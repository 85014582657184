.block {
	border-radius: 0.4rem;

	&-head,
	&-content,
	&-bottom {
		padding: 1rem;
	}

	&-head {
		font-size: 1.125rem;
		font-weight: 600;
		min-height: 40px;
		position: relative;

		.close {
			border-radius: 0 8px 0 0;
			bottom: 0;
			cursor: pointer;
			position: absolute;
			right: 0;
			top: 0;
			width: 40px;

			img {
				height: 16px;
				width: 16px;
			}
		}
	}

	._error {
		color: #f46a6a;
		font-size: 13px;
		&-border {
			border: 1px solid #f46a6a;
		}
	}

	&-bottom {
		border-top: 1px solid $color-bg;
		justify-content: end;
	}

	@media #{$mediaMd} {
		&-head,
		&-content,
		&-bottom {
			padding: 0.5rem;
		}
	}
}
