.card {
	border-radius: 0.25rem;
	margin-bottom: 30px;

	&-header,
	&-content,
	&-footer {
		padding: 0.75rem 1.25rem;
	}

	&-header {
		border-radius: 0.25rem 0.25rem 0 0;
		font-size: 1.35rem;
	}

	&-content {
		padding: 1.25rem;
	}

	@media #{$mediaMd} {
		&-header,
		&-content,
		&-footer {
			padding: 0.5rem 1rem;
		}

		&-header {
			font-size: 1.15rem;
		}
	}
}
