#menu {
	transition: all 0.3s;

	.drop-down {
		height: 0;
		opacity: 0;
		transition: all 0.3s;
		visibility: hidden;
		padding-left: 15px;
	}
	._viewed {
		height: 100%;
		opacity: 1;
		visibility: inherit;

		// transform: rotate(180deg);
	}
	.icon {
		transition: all 0.3s;
	}
	.transition-icon {
		transition: all 0.3s;
		transform: rotate(180deg);
	}
}
