._dark {
	background-color: #22252f;
	.header {
		background: #2d313e;
	}
	#menu {
		background-color: #2b2e3b;
		.name {
			color: #fff;
		}
		.link {
			color: #fff;
			path {
				fill: $color-text;
			}

			&:hover,
			&._active {
				color: $color-hover;

				path {
					fill: $color-hover;
				}
			}
		}
	}

	.card {
		background: #2d313e;
		color: $color-text;
		&-header {
			background: #272c38;
			color: #f6f6f6;
		}
	}

	table {
		color: #9295a4;
		border: 1px solid #343747;

		th {
			color: #fff;
			background: #343747;
		}

		td {
			border-top: 1px solid #343747;
		}
	}

	.block {
		background: #2b2e3b;
		border: 1px solid #2b2e3b;
		&-head {
			border-bottom: 1px solid $color-bg;
			color: #f6f6f6;
			.close {
				background: $color-bg;
			}
		}
	}
	.form {
		&-label {
			color: #fff;
		}
		&-input {
			background: #3c4052;
			border: 1px solid #60647b;
			color: #fff;
		}
	}
	._select {
		background-image: url("../../../img/icon/arrow-select.svg");
		background-position: calc(100% - 10px) 50%;
		background-repeat: no-repeat;
		background-size: 12px;
	}
	.dropzone {
		background: #2f3341;
		border: 2px dashed #565e77;
	}

	.checkbox {
		background: #3a3e50;
		&:checked {
			background: $color-bg url("../../../img/icon/checked.svg") 50% no-repeat;
			background-size: 10px;
		}
	}
	.scroll {
		&-y {
			&-custom {
				&::-webkit-scrollbar {
					background-color: #2d313e;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #b4b4b4;
				}
			}
		}

		&-x {
			&::-webkit-scrollbar-thumb {
				background-color: #b4b4b4;
				border-radius: 20px;
			}
		}
	}

	.page-title {
		color: $color-text;
	}

	.footer {
		a {
			color: #fff;
		}
	}

	.text {
		span {
			color: #fff !important;
		}
	}

	.page-events {
		.card-content {
			strong {
				color: #fff !important;
			}
			span {
				color: #fff !important;
			}
			a {
				color: rgb(98, 98, 255) !important;
			}
		}
	}

	.messages {
		.message {
			background: #2b2e3b;
			border-bottom: 1px solid #212431;
			border-top: 1px solid #363a4c;
			&._new {
				background: #3c4052;
				border-bottom: 1px solid #2c3040;
				border-top: 1px solid #494f65;
			}
			&:hover {
				background: #41455a;
			}
		}
	}

	footer {
		a {
			color: #fff;
		}
	}

	.pagination {
		background: #2b2e3b;
		box-shadow: inset 0 0 0 1px #343747;

		.link,
		.break {
			&:hover {
				color: #fff;
			}
			&:not(:last-child) {
				border-right: 1px solid #343747;
			}
		}
	}

	.paperClip {
		path {
			fill: #fff;
		}
	}

	.go-table-link:hover {
        background-color: #343747;
      }
}
