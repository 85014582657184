@import "../variables";

.profile {
	.name {
		border-bottom: 1px solid $color-text;
		padding-bottom: 10px;
	}
	
	.type {
		font-size: 14px;
		margin-bottom: 5px;
		margin-top: 15px;
	}
	
	.info {
		background: #2f3341;
		background-clip: padding-box;
		border: 1px solid #343747;
		border-radius: 0.25rem;
		color: #9699a8;
		font-size: 0.9rem;
		font-weight: 400;
		height: calc(1.5em + 0.94rem + 2px);
		line-height: 1.5;
		padding: 0.47rem 0.75rem;
		width: 100%;
	}
	
	.error_form {
		border: 1px solid #f46a6a;
	}
	
	.error_text {
		color: #f46a6a;
		font-size: 12px;
		list-style: none;
		margin-top: 5px;
	}
	
	.ch-block {
		margin-top: 30px;
		
		.checkbox {
			margin-top: 10px;
		}
		
		input {
			margin-right: 10px;
		}
	}
	
	.save {
		margin-top: 30px;
	}
	
	@media #{$mediaLg} {
		.info {
			padding: 5px;
		}
		.type {
			margin-top: 10px;
		}
	}
}
