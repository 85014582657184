._light {
	background-color: #fff;
	.header {
		background-color: #e7e7e7;
		.user {
			span {
				color: #848484;
			}
		}
	}
	#menu {
		background-color: #f3f3f3;
		.name {
			color: #848484;
		}
		.link {
			color: #848484;
			path {
				fill: #848484;
			}

			&:hover,
			&._active {
				color: $color-hover;

				path {
					fill: $color-hover;
				}
			}
		}
	}

	.card {
		background-color: #f3f3f3;
		color: #495057;
		&-header {
			background-color: #e7e7e7;
			color: #495057;
		}
	}

	table {
		color: #9295a4;

		th {
			color: #9295a4;
			background-color: #e7e7e7;
		}

		td {
			border-top: 1px solid #d3d3d3;
		}
	}

	.block {
		background: #e7e7e7;
		border: 1px solid #e7e7e7;
		&-head {
			border-bottom: 1px solid #ced4da;
			color: #495057;
			.close {
				background: #cecece;
			}
		}
	}
	.form {
		&-label {
			color: #495057;
		}
		&-input {
			background-color: #d9d9d9;
			color: #495057;
			border: 1px solid #848ba7;
		}
	}
	.dropzone {
		background: #d9d9d9;
		border: 2px dashed #7b8190;
		color: #7b8190;
	}

	.scroll {
		&-y {
			&-custom {
				&::-webkit-scrollbar {
					// background-color: #ffffff;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #b4b4b4;
				}
			}
		}

		&-x {
			&::-webkit-scrollbar-thumb {
				background-color: #b4b4b4;
				border-radius: 20px;
			}
		}
	}
	.page-title {
		color: #848484;
	}

	footer {
		a {
			color: #848484;
		}
	}

	.text {
		span {
			color: #848484 !important;
		}
	}

	.page-events {
		.card-content {
			strong {
				color: #495057 !important;
			}
			span {
				color: #495057 !important;
			}
			a {
				color: rgb(98, 98, 255) !important;
			}
		}
	}

	.messages {
		.message {
			background-color: #ebebeb;
			border-bottom: 1px solid #ced4da;
			color: #7b8190;
			&._new {
				background: #d8d8d8;
			}
			&:hover {
				background: #d1d1d1;
			}
		}
	}
	.pagination {
		background: #e7e7e7;
		box-shadow: inset 0 0 0 1px #878d9c;
		.link,
		.break {
			&:hover {
				color: #000;
			}
			&:not(:last-child) {
				border-right: 1px solid #878d9c;
			}
		}
	}
	.chat-wrap {
		.file {
			background: #acacacbf;
		}
	}
	.paperClip {
		path {
			fill: #000;
		}
	}

	.go-table-link:hover {
        background-color: #e7e7e7;
    }
}
