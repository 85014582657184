@import "../variables";

.uil {
	&-arrow {
		&::after {
			content: "\ebb5";
		}
	}
}

.btn {
	align-items: center;
	border: 1px solid #0000001f;
	border-radius: 5px;
	cursor: pointer;
	display: inline-flex;
	font-family: $font__name;
	font-size: 13px;
	font-weight: 500;
	justify-content: center;
	line-height: 1;
	padding: 11.5px 20px;
	transition: all 0.3s;
	
	&-bg-acent {
		background-color: #5b73e8;
		color: #fff;
	}
	
	&-primary {
		background-color: #5b73e8;
		color: #fff;
		
		&:hover {
			background-color: #3956e3;
		}
	}
	
	&-light {
		background-color: #f5f6f8;
		color: #212529;
		
		&:hover {
			background-color: #dfe2e8;
		}
	}
	
	&-success {
		background-color: #34c38f;
		color: #fff;
		
		&:hover {
			background-color: #2ca579;
		}
	}
	
	&-info {
		background-color: #50a5f1;
		color: #fff;
		
		&:hover {
			background-color: #2d93ee;
		}
	}
	
	&-warning {
		background-color: #f1b44c;
		color: #fff;
		
		&:hover {
			background-color: #eea529;
		}
	}
	
	&-danger {
		background-color: #f46a6a;
		color: #fff;
		
		&:hover {
			background-color: #f14646;
		}
	}
	
	&-dark {
		background-color: #343a40;
		color: #fff;
		
		&:hover {
			background-color: #23272b;
		}
	}
	
	&-link {
		color: #5b73e8;
		font-weight: 400;
		
		&:hover {
			color: #1e3ed8;
			text-decoration: underline;
		}
	}
	
	&-secondary {
		background-color: #74788d;
		color: #fff;
		
		&:hover {
			background-color: #636678;
		}
	}
	
	&-sm {
		font-size: 14px;
		padding: 12px 24px;
	}
	
	&-xl {
		font-size: 16px;
		padding: 15px 20px;
	}
	
	&-block {
		width: 100%;
	}
	
	svg {
		height: 16px;
		object-fit: contain;
		width: 16px;
		
		&.icon-left {
			margin-right: 10px;
		}
		
		&.icon-right {
			margin-left: 10px;
		}
	}
	
	@media #{$mediaLg} {
		font-size: 14px;
		padding: 6px 15px;
	}
}
