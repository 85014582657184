.checkbox {
	border: #50556d solid 1px;
	border-radius: 4px;
	display: block;
	height: 20px;
	width: 20px;

	&:checked {
		background: #5b73e8 url("/static/img/icon/ok.svg") 50% no-repeat;
		background-size: 10px auto;
		border-color: #5b73e8;
	}
}
