@import "../../../static/styles/inc/variables";

.modal {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9998;

	.btn-lock {
		background-color: #8787873b !important;
	}

	&:after {
		content: "";
		cursor: pointer;
		position: fixed;
		z-index: -1;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background: fade-out(#000, 0.2);
	}

	.block {
		max-width: 1000px;
		margin: 15px auto;
		max-height: calc(100% - 30px);

		&-content {
			height: auto;
		}
	}

	@media #{$mediaMd} {
		.block {
			width: 100%;
			margin: 0;
			height: 100%;
		}
	}
}
