table {
	width: 100%;
	min-width: 1050px;

	&.min-0 {
		min-width: 0;
	}

	th,
	td {
		text-align: left;
		padding: 10px 15px;
	}

	td {
		vertical-align: middle;
	}

	th {
		font-weight: 700;
	}

	.btn {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
	}

	@media #{$mediaMd} {
		th,
		td {
			padding: 0.4rem;
		}
	}
}
