.form {
	&-field {
		display: block;
		margin-bottom: 1.75rem;
	}

	&-label {
		display: block;
		margin-bottom: 0.4rem;
	}

	&-input {
		border-radius: 0.25rem;
		font-size: 0.9rem;
		line-height: 1.5;
		padding: 9px 15px 10px;

		&._select {
			background: #2f3341 url("../../../static/img/icon/arrow-select.svg")
				calc(100% - 10px) 50% no-repeat;
			background-size: 12px auto;
			cursor: pointer;
			padding-right: 30px;
		}

		&[type="date"] {
			padding: 9px 15px;
		}

		&[readonly] {
			color: #fff;
			cursor: caret;
		}

		&[disabled] {
			cursor: default;
		}

		&._error {
			border-color: #f46a6a;
		}
	}

	&-error {
		color: #f46a6a;
		font-size: 80%;
		margin-top: 0.4rem;
	}
}
