.upload-doc {
	position: fixed;
	z-index: 9999;
	top: 0;
	color: #fff;
	background-color: #0000007a;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	align-items: center;
	vertical-align: middle;
	display: flex;
	justify-content: center;
}
