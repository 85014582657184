.file {
	border: 1px solid #565e77;
	border-radius: 4px;
	font-size: 14px;
	margin-top: 5px;
	padding: 8px;
	cursor: pointer;

	&-img {
		background: #fff;
		border-radius: 4px;
		height: 42px;
		overflow: hidden;
		width: 42px;

		.doc-icon {
			height: 26px;
			width: 26px;
		}
	}

	&-size {
		font-size: 12px;
		opacity: 0.4;
	}

	&-remove {
		height: 42px;
		width: 42px;
	}
}
