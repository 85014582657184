.avatar {
	&-rounded {
		border-radius: 0.25rem !important;
	}
	
	&-circle {
		border-radius: 50% !important;
	}
	
	&-sm {
		height: 3rem;
		width: 3rem;
	}
	
	&-md {
		height: 4.5rem;
		width: 4.5rem;
	}
	
	&-lg {
		height: 6rem;
		width: 6rem;
	}
}
