.page-title {
	font-size: 2.25rem;
	font-weight: 500;
	margin-bottom: 1.5rem;
}

h1,
.h1 {
	font-size: 2.25rem;
}

h2,
.h2 {
	font-size: 1.8rem;
}

h3,
.h3 {
	font-size: 1.575rem;
}

h4,
.h4 {
	font-size: 1.35rem;
}

h5,
.h5 {
	font-size: 1.125rem;
}

h6,
.h6,
p {
	font-size: 0.9rem;
}

.h7 {
	font-size: 0.75rem;
}

.f {
	/* Text case */
	&-upper {
		text-transform: uppercase;
	}

	&-lower {
		text-transform: lowercase;
	}

	/* Font weight */
	&-700 {
		font-weight: 700;
	}

	&-500 {
		font-weight: 500;
	}

	&-400 {
		font-weight: 400;
	}

	&-300 {
		font-weight: 300;
	}
}

/* Text align */
.ta {
	&-left {
		text-align: left;
	}

	&-center {
		text-align: center !important;
	}

	&-right {
		text-align: center !important;
	}
}

/* Text color */
.c {
	&-text {
		color: $color-text;
	}

	&-text-fade {
		color: $color-text;
	}
}
