@import "./variables";

html {
	font-size: 16px;
}

html,
body {
	height: 100%;
}

body {
	color: #9699a8;
	font: 400 16px/1.4 $font__name;
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

.content-block {
	padding: 90px 30px 30px 290px;
	width: 100%;

	@media #{$mediaXl} {
		padding: 90px 15px 50px 15px;
	}
}

/* Position */
[class*="pos-abs"] {
	position: absolute;
}

.pos {
	position: relative;
	z-index: 1;

	&-abs {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&-abs-y {
		top: 50%;
		transform: translate(0, -50%);
	}

	&-abs-x {
		left: 50%;
		transform: translate(-50%, 0);
	}
}

/* Image */
[class*="image-"] {
	width: 100%;
	height: 100%;
}

.image {
	&-cover {
		object-fit: cover;
	}

	&-contain {
		object-fit: contain;
	}
}

/* Scroll */
[class*="scroll-"] {
	-webkit-overflow-scrolling: touch;
}

.scroll {
	&-y {
		overflow: hidden auto;

		&::-webkit-scrollbar {
			width: 12px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 20px;
		}
	}

	&-x {
		overflow: auto hidden;

		&::-webkit-scrollbar {
			height: 8px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 20px;
		}
	}
}
.form {
	&-label {
		color: #495057;
	}
	&-input {
		background-color: #d9d9d9;
		color: #495057;
		border: 1px solid #848ba7;
	}
}

/* Cursor */
.cursor {
	&-pointer {
		cursor: pointer !important;
	}

	&-default {
		cursor: default !important;
	}
}

/*alert */

.react-confirm-alert-overlay {
	z-index: 100 !important;
	background-color: #292929ab !important;
}

.react-confirm-alert-button-group {
	button:nth-child(1) {
		background-color: #5b73e8 !important;
	}

	button:nth-child(2) {
		background-color: #f46a6a !important;
	}
}

.report-notification {
       padding: 10px 20px;
        font-weight: 500;
        color: white;
        background: #5b73e8;
        margin-bottom: 10px;
        box-shadow: 0 0 12px #5b73e8;
}

.report-notification-important {
  padding: 10px 20px;
  font-weight: 500;
  color: white;
  background: brown;
  margin-bottom: 10px;
  box-shadow: 0 0 12px #5b73e8;
}

