* {
	appearance: none;
	box-sizing: border-box;
	margin: 0;
	outline: none;
	padding: 0;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
	border: 0;
	font: inherit;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	text-rendering: optimizeLegibility;
	text-size-adjust: none;
	vertical-align: baseline;
}

footer,
header,
nav,
section,
main {
	display: block;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

input,
textarea,
button,
select {
	background: none;
	border: none;
	outline: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input {
	-webkit-appearance: none;
	border-radius: 0;
}

a {
	text-decoration: none;
}
