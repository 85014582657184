.pagination {
	border-radius: 0.25em;
	overflow: hidden;

	.link {
		color: #9699a8;
		cursor: pointer;
		display: inline-block;
		height: 39px;
		line-height: 40px;
		text-align: center;
		width: 40px;

		&.active {
			background: #5b73e8;
			border-color: #5b73e8;
			a {
				color: #fff !important;
			}
		}
	}
}
