@import "../variables";

.message {
	.btn-lock {
		background-color: #8787873b !important;
	}
	.text {
		p,
		span {
			font-size: 14px;
		}
	}
	.childrens {
		padding: 30px 30px;
	}
	a {
		color: #5a6fef;
	}

	.left-border {
		border-left: 5px solid #7c7c7c52;
	}
	.mail {
		border-bottom: 1px solid #72727236;
		img {
			max-width: 100px;
			object-fit: contain;
		}
	}
	.small-date {
		font-size: 13px;
	}
	.file-mail {
		font-size: 13px;
		margin-top: 10px;
		margin-bottom: 10px;
		display: inline-block;
		color: #5a6fef;
		transition: all 0.3s;
		cursor: pointer;
		&:hover {
			color: #5465cc;
		}
	}

	.files {
		display: flex;
		flex-direction: column;
	}
}
.modal {
	.ql-editor {
		min-height: 200px !important;
	}
}
