@import "../variables";

.inbox {
	.ql-editor {
		min-height: 200px;
	}

	.btn-lock {
		background-color: #8787873b !important;
	}

	.from {
		width: 15%;
	}

	.ch-block {
		width: 10%;
	}

	.messages {
		border-radius: 8px;

		// a {
		// 	color: $color-text;
		// }

		.message {
			padding: 12px 0;
			transition: all 0.3s;

			* {
				line-height: 1.4;
			}
			.text {
				span {
					background: none !important;
				}
			}

			.from {
				width: 300px;
				overflow: hidden;

				@include text-overflow(1, 1rem * 1.4);
			}

			.text {
				overflow: hidden;
				width: calc(100% - 350px);
				img {
					display: none;
				}
				@include text-overflow(1, 1rem * 1.4);
				h1,
				h2,
				h3,
				h4,
				h5 {
					font-size: 15px;
				}
			}

			.date {
				overflow: hidden;
				text-align: left;
				// width: 150px;
				@include text-overflow(1, 1rem * 1.4);
			}

			&:first-child {
				border-top: none;
			}

			&:last-child {
				border-bottom: none;
			}
		}

		li {
			color: $color-text;

			padding: 10px;
			transition: all 0.3s;
			a {
				color: $color-text;
			}

			&:hover {
				background: #343747;
			}
		}

		label {
			width: 29px;
		}
	}

	.chat-wrap {
		height: 100%;

		.item {
			align-items: flex-start;
			display: flex;
			flex-direction: column;

			.msg-wrap {
				max-width: calc(100% - 50px);
			}

			.text-wrap {
				background: #3c4052;
				border-radius: 8px 8px 8px 0;
				color: #fff;
				font-size: 13px;
				padding: 3px 10px;
				word-break: break-word;
			}

			.file {
				border-radius: 6px;
				text-align: left;

				&-img {
					background: #fff;
					border-radius: 6px;
					height: 32px;
					width: 32px;
				}
			}

			&._me {
				align-items: flex-end;
				text-align: right;

				.text-wrap {
					background: #5b73e8;
					border-radius: 8px 8px 0 8px;
				}
			}
		}
	}

	@media #{$mediaMd} {
		.msg-menu {
			display: flex;
			flex-direction: column;

			button {
				margin-bottom: 15px;
				margin-right: 0;
				width: 100%;
			}

			.input {
				margin-left: 0;
				width: 100%;

				input {
					width: 100%;
				}
			}
		}
	}

	@media #{$mediaSm} {
		.teaser {
			display: none !important;
		}

		.from,
		.date {
			display: block;
			text-align: center;
			width: 100%;
		}

		.chat-wrap {
			.item {
				.msg-wrap {
					max-width: calc(100% - 15px);
				}
			}
		}
	}
}
