.check-theme {
	label {
		display: flex;
	}
	.switch {
		width: 80px;
		height: 22px;
		background: #919191;
		margin: 0;
		padding: 0;
		appearance: none;
		border: none;
		cursor: pointer;
		position: relative;
		border-radius: 16px;
		transition: 0.3s all;

		.circle {
			transition: 0.3s all;
			width: 20px;
			position: absolute;
			left: 1px;
			top: 1px;
			height: 20px;
			background: #ffffff;
			border-radius: 100%;
		}
	}
	.active {
		background: #4cd964 !important;
		.circle {
			left: 19px !important;
		}
	}
}
