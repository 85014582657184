@font-face {
	font-family: "SFUIDisplay";
	src: url("../../fonts/SFUIDisplay-Bold.eot");
	src: url("../../fonts/SFUIDisplay-Bold.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/SFUIDisplay-Bold.woff2") format("woff2"),
	url("../../fonts/SFUIDisplay-Bold.woff") format("woff"),
	url("../../fonts/SFUIDisplay-Bold.ttf") format("truetype"),
	url("../../fonts/SFUIDisplay-Bold.svg#SFUIDisplay-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SFUIDisplay";
	src: url("../../fonts/SFUIDisplay-Medium.eot");
	src: url("../../fonts/SFUIDisplay-Medium.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/SFUIDisplay-Medium.woff2") format("woff2"),
	url("../../fonts/SFUIDisplay-Medium.woff") format("woff"),
	url("../../fonts/SFUIDisplay-Medium.ttf") format("truetype"),
	url("../../fonts/SFUIDisplay-Medium.svg#SFUIDisplay-Medium") format("svg");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SFUIDisplay";
	src: url("../../fonts/SFUIDisplay-Light.eot");
	src: url("../../fonts/SFUIDisplay-Light.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/SFUIDisplay-Light.woff2") format("woff2"),
	url("../../fonts/SFUIDisplay-Light.woff") format("woff"),
	url("../../fonts/SFUIDisplay-Light.ttf") format("truetype"),
	url("../../fonts/SFUIDisplay-Light.svg#SFUIDisplay-Light") format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SFUIDisplay";
	src: url("../../fonts/SFUIDisplay-Regular.eot");
	src: url("../../fonts/SFUIDisplay-Regular.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/SFUIDisplay-Regular.woff2") format("woff2"),
	url("../../fonts/SFUIDisplay-Regular.woff") format("woff"),
	url("../../fonts/SFUIDisplay-Regular.ttf") format("truetype"),
	url("../../fonts/SFUIDisplay-Regular.svg#SFUIDisplay-Regular") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
