.header {
	box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
	left: 0;
	padding: 15px 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100;

	.mob-menu {
		width: 24px;
	}

	.l-side {
		padding: 0 15px;
		width: 260px;

		.logo {
			color: $color-text;
			width: 100px;

			img {
				width: 100%;
			}
		}
	}

	.r-side {
		padding: 0 15px;

		.email {
			height: 22px;
			width: 22px;
		}

		.user {
			.avatar {
				height: 36px;
				width: 36px;
			}
		}
	}

	@media #{$mediaMd} {
		padding: 10px 0;

		.l-side {
			.logo {
				width: 110px;
			}
		}
	}

	@media #{$media475} {
		.r-side {
			right: 15px;

			.email {
				height: 20px;
				margin-right: 5px;
				width: 20px;
			}

			.user {
				margin-left: 5px;

				img {
					margin-right: 8px;
					width: 30px;
				}
			}
		}
	}
}
