@import "../variables";

#menu {
	color: $color-text;
	position: fixed;
	left: 0;
	top: 60px;
	bottom: 0;
	z-index: 99;
	font-size: 12px;
	padding: 30px 15px;
	width: 260px;

	.link {
		font-size: 16px;
		padding: 6px 0;
		margin-bottom: 10px;
		color: $color-text;
		align-items: center;
		transition: all 0.2s;
		display: block;
		position: relative;
		padding-left: 35px;

		svg {
			left: 0;
		}

		path {
			transition: all 0.2s;
		}

		.msg-num {
			background-color: #5b73e8;
			font-size: 11px;
			color: white;
			padding: 5px 10px 3px;
			border-radius: 15px;
			line-height: 1;
			right: 75px;
		}
	}

	@media #{$mediaXl} {
		top: 53px;
		left: -100%;
		transition: left 0.3s;

		&._visible {
			left: 0;
		}
	}
}
