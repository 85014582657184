footer {
	color: #898fa9;
	padding: 15px 30px 15px 290px;

	@media #{$mediaXl} {
		padding: 15px;
	}

	@media #{$mediaMd} {
		font-size: 13px;
	}
}
