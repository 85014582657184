@import "../variables";

.upload {
	input {
		border: 1px solid $color-text;
		border-radius: 8px;
		color: $color-text;
		margin-bottom: 5px;
		margin-top: 5px;
		padding: 10px;
		width: 100%;
	}
}

.uploadMod {
	input {
		border: 1px solid $color-text;
		border-radius: 8px;
		color: $color-text;
		margin-bottom: 5px;
		margin-top: 5px;
		padding: 10px;
		width: 100%;
	}
}
